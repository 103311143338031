// Typography
$rubik: "Rubik", sans-serif;
$work-sans: "Work Sans", sans-serif;
$roboto: "Roboto", sans-serif;

$black: #000;
$white: #fff;
$light-gray: #e5e5e5;
$medium-gray: #5e5e5e;
$gray: #9c9c9c;
$green: #3fe193;
$red: #f50000;
$background: linear-gradient(90deg, #014a58 0.44%, #011d33 99.94%);

// Colors
$sw-white: #fff;
$sw-black: #000000;
$sw-lime: #d1f669;
$sw-red: #ce2b79;
$sw-red2: #e30511;
$sw-red-dark: #8b1739;
$sw-blue-medium: #014a58;
$sw-blue-dark: #011d33;

$primary-color: $sw-lime;

@import "../../styles/base.scss";

.match_card__container {
  display: flex;
  flex-direction: column;
  padding: px-to-vw(15, 430);
  background: rgba(255, 255, 255, 0.1);
  border-radius: px-to-vw(4, 430);

  .divider {
    padding: 2.721vw 0;
    width: 100%;

    hr {
      width: 100%;
      height: 0;
      border: 0;
      border-bottom: 1px solid rgba($white, 0.1);
      margin: 0;
    }
  }

  .choices_and_prediction {
    display: block;
    grid-template-columns: repeat(2, 1fr);
    margin-top: 3.023vw;
    gap: px-to-vw(10, 430);

    .choices {
      display: flex;
      flex-direction: column;
      gap: px-to-vw(6, 430);
      height: max-content;

      .choice_container {
        display: grid;
        grid-template-columns: repeat(2, 1fr);

        &.without_bets {
          grid-template-columns: 3fr 1fr;

          .choice {
            width: fill;
            text-align: right;
          }
        }

        .choice {
          padding-left: 1vw;
          text-align: right;
        }

        .type,
        .choice,
        .points,
        .value {
          font-size: px-to-vw(12, 430);
          font-weight: 400;
          line-height: 4.186vw;
          text-transform: initial;
          color: $white;
        }

        .choice,
        .value {
          font-family: $rubik;
          font-size: 2.791vw;
          font-style: normal;
          font-weight: 700;

          &.win {
            color: $primary-color;
          }

          &.lose {
            opacity: 0.6;
          }
        }
      }
    }

    .btn {
      max-width: 100%;
      width: 100%;
      position: relative;
      margin-top: px-to-vw(20, 430);
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 2.674vw 1.512vw;
      border-color: $primary-color;
      background-color: $primary-color;
      border-radius: 2px;
      .text {
        font-family: $rubik;
        font-size: px-to-vw(12, 430);
        font-style: normal;
        font-weight: 700;
        line-height: normal;
        text-transform: uppercase;
        color: $sw-blue-medium;
      }

      .icon {
        display: block;
        width: 7.907vw;

        -webkit-clip-path: polygon(0 0, 0 100%, 100% 100%, 100% 25%, 75% 0);
        clip-path: polygon(0 0, 0 100%, 50% 100%, 100% 0%, 100% 0);
        display: flex;
        align-items: center;
        img {
          width: 3.419vw;
          height: auto;
          display: block;
        }
      }
    }

    .live_game {
      position: relative;
      display: flex;
      align-content: center;
      font-size: 2.791vw;
      font-weight: 700;
      line-height: 3.256vw;
      color: $black;
      text-transform: uppercase;
      padding: 2.674vw;
      white-space: no-wrap;
      padding: 2.674vw 5.581vw;

      border: 0.349vw solid rgba($color: $sw-white, $alpha: 0.5);
      background: rgba($color: $sw-white, $alpha: 0.3);
      border-color: $primary-color;
    }

    .score_card {
      display: block;
      height: 100%;

      & > div {
        width: 100%;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
      }

      .title {
        font-size: 2.791vw;
        font-style: normal;
        font-weight: 400;
        line-height: 3.721vw;
        text-transform: capitalize;
        margin-bottom: 2.093vw;
        text-align: center;
        color: $white;

        font-family: $rubik;
      }

      .score {
        color: $white;
        font-family: $rubik;
        font-size: 3.721vw;
        font-style: normal;
        font-weight: 700;
        line-height: 3.721vw;
      }

      .points {
        color: $primary-color;
        font-family: $rubik;
        font-size: 3.721vw;
        font-style: normal;
        font-weight: 700;
        line-height: 3.721vw;
      }
    }

    .prediction {
      display: flex;
      flex-direction: column;
      justify-content: center;
    }
  }
  .before_match {
    text-align: center;
    padding: px-to-vw(12, 430) 0;
    &:empty {
      display: none;
    }
    p {
      font-family: $rubik;
      font-size: px-to-vw(12, 430);
      font-style: normal;
      font-weight: 400;
      line-height: px-to-vw(24, 430);
      color: $white;
    }
  }
}

.subText {
  font-family: $rubik;
  font-size: px-to-vw(12, 430);
  margin-top: px-to-vw(12, 430);
  font-style: normal;
  line-height: 1.3;
  font-weight: 400;
  color: $white;
}

@import "../../styles/base.scss";

.matches {
  margin-top: px-to-vw(24, 430);
  .user-profile {
    background: $primary-color;
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 3.721vw;
    margin-bottom: px-to-vw(16, 430);
    border-radius: px-to-vw(4, 430);
    overflow: hidden;

    &--data {
      display: flex;
      align-items: center;
      gap: 3.721vw;
      padding: 1.86vw 3.721vw;

      .user-image {
        display: block;
        width: 9.302vw;
        height: 9.302vw;

        img {
          width: 9.302vw;
          height: 9.302vw;
          border-radius: 50%;
          overflow: hidden;
          object-fit: cover;
        }
      }

      .nickname {
        font-size: 3.721vw;
        font-weight: 600;
        line-height: 4.419vw;
        color: $sw-blue-medium;
      }
    }

    &--ranking {
      display: flex;
      align-items: center;

      .points {
        font-size: 3.721vw;
        font-weight: 600;
        line-height: 4.419vw;
        color: $sw-blue-medium;
        white-space: nowrap;
        margin-right: -1.86vw;

        span {
          font-size: 2.558vw;
        }
      }

      .rank {
        display: block;
        height: 13.023vw;
        font-size: 3.721vw;
        font-weight: 600;
        line-height: 4.419vw;
        color: $sw-white;
        white-space: nowrap;

        background: $sw-red;
        padding: 4.651vw 3.721vw 4.419vw 9.302vw;
        -webkit-clip-path: polygon(9.302vw 0%, 0 100%, 100% 100%, 100% 100%, 100% 0);
        clip-path: polygon(9.302vw 0%, 0 100%, 100% 100%, 100% 100%, 100% 0);
      }
    }
  }

  > h2 {
    font-family: $rubik;
    font-size: px-to-vw(20, 430);
    line-height: normal;
    color: $white;
    margin-bottom: px-to-vw(12, 430);
    letter-spacing: px-to-vw(0.4, 430);
    text-transform: uppercase;
    font-weight: 700;
  }

  .select-container {
    margin-top: 3.721vw;
    margin-bottom: 1.86vw;
  }

  .matches-list {
    margin-top: 3.721vw;
    .match-card {
      padding: 1.86vw 0;
    }
  }

  .banner-before-competition {
    padding-top: 5.581vw;

    img {
      width: 100%;
      height: auto;
    }
  }

  .teaser {
    width: 100%;
  }

  .filters {
    background-color: $white;
    display: flex;
    position: relative;

    .filter {
      flex: 1;
      text-align: center;
      font-family: $rubik;
      font-size: px-to-vw(12, 430);
      font-style: normal;
      font-weight: 500;
      line-height: px-to-vw(16, 430);
      padding: px-to-vw(15, 430) 0;
      color: $gray;

      &.active {
        color: $primary-color;
      }
    }

    .active_bar {
      width: 50%;
      height: px-to-vw(4, 430);
      background-color: $primary-color;
      position: absolute;
      bottom: 0;
      left: 0;
      transition: all 0.5s;

      &.right {
        transform: translateX(100%);
      }
    }
  }
}
.loader_container {
  height: 50vh;
  display: flex;
  align-items: center;
  justify-content: center;
}

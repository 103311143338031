@import "../../styles/base.scss";

.chat_container {
  .conversation_item {
    background-color: transparent;
    padding: 3.721vw 0;
    border-bottom: 1px solid rgba($sw-white, $alpha: 0.1);
    transition: all 0.5s;
    &:hover {
      background-color: transparent;
    }

    .cs-conversation__content {
      .cs-conversation__name {
        color: $sw-white;
        font-family: $work-sans;
        font-size: 3.256vw;
        font-style: normal;
        font-weight: 600;
        line-height: 4.186vw;
        margin-bottom: 2vw;
      }
      .cs-conversation__info {
        color: $sw-white;
        font-family: $work-sans;
        font-size: 3.256vw;
        font-style: normal;
        font-weight: 400;
        line-height: 4.186vw;
      }
    }
    .avatar {
      border-radius: 0;

      width: 9.302vw;
      height: 9.302vw;

      img {
        border-radius: 0;
        width: 100%;
        height: 100%;
        object-fit: contain;
      }
    }
    .cs-conversation__last-activity-time {
      position: absolute;
      top: 3.721vw;
      right: 0;
      font-family: $work-sans;
      font-size: 2.791vw;
      font-style: normal;
      font-weight: 400;
      line-height: 3.721vw;
      color: rgba($sw-white, $alpha: 0.6);
    }
  }
}

@import "../../styles/base.scss";

.select-container {
  position: relative;
  width: 100%;

  font-size: 2.791vw;
  font-weight: 700;
  line-height: 3.256vw;
  color: rgba($sw-white, $alpha: 0.8);
  text-align: left;
  text-transform: uppercase;

  border: 2px solid $primary-color;
  margin-bottom: 3.721vw;
  max-height: 10.698vw;
  overflow: hidden;
  border-radius: px-to-vw(4, 430);
  &.extended {
    max-height: 100vh;
  }

  .dropdown {
    cursor: pointer;
    display: block;
    padding: 3.256vw;
    background-color: rgba($color: $sw-white, $alpha: 0.1);

    .icon {
      position: absolute;
      right: -1px;
      top: -1px;
      width: 10vw;
      height: 10vw;
      background-color: $primary-color;
      display: flex;
      justify-content: center;
      align-items: center;
      img {
        width: 3.721vw;
        height: auto;
        transition: all 0.3s ease-in-out;
      }
    }
  }
  &.extended {
    .icon img {
      transform: rotateX(180deg);
    }
  }

  .dropdown-menu {
    max-height: 29.3vw;
    overflow-y: scroll;
    li {
      cursor: pointer;
      display: block;
      padding: 3.256vw;
      background-color: transparent;
      display: flex;
      justify-content: space-between;
      color: rgba($sw-white, $alpha: 0.8);
      &:nth-child(2n) {
        background-color: rgba($color: $sw-white, $alpha: 0.2);
      }
    }
  }
}

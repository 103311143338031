@import "../../styles/base.scss";

.prizes_container {
  margin-top: px-to-vw(24, 430);
  .filters {
    display: flex;
    gap: px-to-vw(13, 430);
    .button {
      flex: 1;
      padding: px-to-vw(13, 430) px-to-vw(2, 430);
      border: none;
      border-radius: px-to-vw(4, 430);
      background-color: rgba($white, 0.2);
      font-family: $rubik;
      font-size: px-to-vw(12, 430);
      font-style: normal;
      font-weight: 700;
      line-height: normal;
      text-transform: uppercase;
      color: $white;

      &.active {
        background-color: $primary-color;
        color: $sw-blue-dark;
        border-color: $primary-color;
      }
    }
  }
  .prizes {
    padding-bottom: 7.209vw;
    .prize_card {
      position: relative;
      display: block;
      margin-top: 3.721vw;

      &__images {
        display: block;
        width: 100%;
        height: auto;
        font-size: 0;

        img {
          width: 100%;
          height: auto;
        }
      }
      &__info {
        padding: 3.721vw;
        display: flex;
        flex-direction: column;
        gap: 1.86vw;
        background-color: rgba($color: $sw-blue-dark, $alpha: 0.6);
        .title,
        .subtitle {
          font-size: 2.791vw;
          font-weight: 400;
          line-height: 1.6;
          color: $sw-white;
        }
        .title {
          font-weight: 700;
          color: $sw-lime;
        }
      }
    }
  }
  .rules {
    padding: px-to-vw(8, 430);
    margin-top: px-to-vw(24, 430);

    border-radius: px-to-vw(4, 430);
    background-color: rgba($white, 0.1);
    h2 {
      color: $primary-color;
      font-size: px-to-vw(18, 430);
      margin-bottom: px-to-vw(10, 430);
    }
    h3 {
      color: $primary-color;
      font-size: px-to-vw(16, 430);
      margin-bottom: px-to-vw(10, 430);
    }
    p {
      color: $white;
      font-size: px-to-vw(14, 430);
      font-family: $rubik;
      margin-bottom: px-to-vw(7, 430);
      line-height: normal;
    }
  }
}

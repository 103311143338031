@import "../../styles/base.scss";

.correct_score {
  h2 {
    font-family: $rubik;
    font-size: px-to-vw(16, 430);
    line-height: normal;
    color: $primary-color;
    margin-bottom: px-to-vw(16, 430);
  }

  .teams_container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: px-to-vw(10, 430);

    .team_container {
      display: flex;
      flex-direction: column;
      gap: px-to-vw(12, 430);
      flex: 1;

      .team_name {
        font-family: $rubik;
        font-size: px-to-vw(14, 430);
        line-height: px-to-vw(18, 430);
        text-align: center;
      }

      .score_container {
        display: flex;
        align-items: center;
        justify-content: space-between;
        gap: px-to-vw(9, 430);
        border-radius: px-to-vw(4, 430);
        background: rgba(255, 255, 255, 0.1);
        width: 100%;

        button {
          background-color: transparent;
          border: none;
          font-family: $rubik;
          font-size: px-to-vw(18, 430);
          font-style: normal;
          font-weight: 500;
          display: flex;
          align-items: center;
          justify-content: center;
          padding: 0;
          color: $white;
          padding: px-to-vw(12, 430) px-to-vw(16, 430);
        }

        .score {
          font-family: $rubik;
          font-size: px-to-vw(18, 430);
          line-height: normal;
          font-weight: 500;
          color: $white;
        }
      }
    }
  }

  .odd {
    text-align: center;
    width: max-content;
    margin: 0 auto;
    margin-top: px-to-vw(8, 430);
    font-family: $rubik;
    font-size: px-to-vw(12, 430);
    line-height: px-to-vw(18, 430);
    color: $white;
  }
}

@import "../../styles/base.scss";

.register_page {
  width: 100vw;
  min-height: 100vh;
  overflow-x: hidden;
  padding-bottom: 7.209vw;
  background: $background;

  .header {
    padding: 3.721vw 7.209vw 0 7.209vw;
    margin-bottom: 14.651vw;

    p {
      color: $sw-white;
      font-size: 3.721vw;
      font-weight: 700;
      line-height: 4.419vw;
      letter-spacing: 0.02em;
      text-align: center;
    }
  }

  .register_info {
    padding: 0 7.209vw;
    margin-bottom: 3.721vw;

    p {
      font-size: 5.581vw;
      font-weight: 700;
      line-height: 6.512vw;
      letter-spacing: 0.02em;
      color: $white;
      text-transform: uppercase;

      span {
        color: $primary-color;
      }
    }
  }

  .trapeze {
    background-color: $primary-color;
    width: max-content;
    padding: 2.826vw 19.535vw 2.326vw 7.209vw;
    filter: drop-shadow(4px 4px 4px rgba(0, 0, 0, 0.25));
    clip-path: polygon(0 0, 100% 1%, 84% 99%, 0% 100%);
    margin-bottom: 7.442vw;
    text-transform: uppercase;

    p {
      font-size: 3.256vw;
      font-weight: 600;
      letter-spacing: 0.28px;
      color: $sw-blue-dark;
      line-height: 110%;

      strong {
        font-weight: 700;
      }
    }
  }

  .title {
    font-size: 2.791vw;
    font-weight: 600;
    line-height: 3.256vw;
    color: $white;
    text-transform: uppercase;
  }

  .user_details {
    padding: 0 7.209vw;

    .title {
      padding: 3.721vw 0;
    }

    .info_email {
      display: flex;
      gap: 1.86vw;
      font-size: 2.791vw;
      line-height: 3.721vw;
      margin-bottom: 1.86vw;

      .label {
        font-weight: 600;
      }

      input {
        display: block;
      }
    }

    .info_nickname {
      display: block;
      font-size: 2.791vw;
      line-height: 3.721vw;
      margin-bottom: 3.721vw;

      .label {
        display: block;
        font-weight: 600;
        margin-bottom: 1.86vw;
        color: $white;
      }

      input {
        display: block;
        width: 100%;
        border: none;
        border-radius: none;
        background-color: rgba($color: $sw-white, $alpha: 0.1);
        padding: 3.256vw;
        border: 2px solid #d1f669;
        font-size: px-to-vw(12, 430);
        line-height: 1;
        font-weight: 400;
        color: $sw-white;
        border-radius: px-to-vw(4, 430);

        &:disabled {
          background-color: rgba($primary-color, 0.6);
        }
      }
    }
  }

  .terms_and_conditions {
    padding: 0 7.209vw;

    .title {
      margin: 5.581vw 0;
    }

    .btn {
      width: 100%;
      position: relative;
      display: flex;
      padding: 2.674vw 1.512vw 2.674vw 10.465vw;
      border: 2px solid $primary-color;
      margin-top: 4.651vw;
      background-color: rgba($white, 0.1);
      color: $white;
      border-radius: px-to-vw(4, 430);

      .icon {
        display: block;
        width: 10.465vw;

        position: absolute;
        top: -0.233vw;
        left: -0.233vw;
        bottom: -0.233vw;
        background-color: $white;

        -webkit-clip-path: polygon(0 0, 0 100%, 100% 100%, 100% 25%, 75% 0);
        clip-path: polygon(0 0, 0 100%, 50% 100%, 100% 0%, 100% 0);

        img {
          width: 3.721vw;
          height: auto;
          display: block;
          position: absolute;
          top: 50%;
          transform: translateY(-50%);
          left: 2.326vw;
        }
      }

      &.accepted {
        .icon {
          background-color: $primary-color;
        }
      }

      .text {
        font-size: 2.558vw;
        font-weight: 400;

        span {
          font-weight: 600;
        }

        line-height: 3.023vw;
        text-transform: uppercase;
      }
    }
  }

  .info {
    .title {
      margin: 5.581vw 0;
    }

    .btn {
      width: 100%;
      position: relative;
      display: flex;
      padding: 2.674vw 1.512vw;
      border: $primary-color;
      margin-top: 4.651vw;

      .text {
        width: 100%;
        font-size: 2.791vw;
        font-weight: 600;

        span {
          font-weight: 600;
        }

        line-height: 3.023vw;
        text-transform: uppercase;
        text-align: center;
      }
    }
  }

  .submit_container {
    margin-top: px-to-vw(64, 430);
    display: flex;
    justify-content: center;

    .btn {
      width: max-content;
      padding: 3.721vw;
      font-family: $rubik;
      font-weight: 700;
      background-color: $primary-color;
      color: $sw-blue-dark;
    }
  }

  ol {
    counter-reset: item;
  }

  li {
    display: block;
    color: #666666;
  }

  ol {
    li:before {
      content: counters(item, ".") " ";
      counter-increment: item;
    }

    &.letter {
      li:before {
        counter-increment: none !important;
        content: none !important;
      }
    }
  }

  ol {
    li {
      margin: 2vw 0;
    }

    ol {
      padding-left: 0vw !important;
      margin: 2vw 0;
    }
  }
}

.error {
  display: block;
  color: $sw-red;
  margin-top: 1.86vw;
  font-size: 2.791vw;
}

.disclaimer {
  font-size: 2.5vw;
  color: $sw-white;
  margin-top: 1.5vw;
}

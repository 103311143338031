@import "../../styles/base.scss";
.chat {
  margin-top: px-to-vw(24, 430);
  .small_title {
    font-family: $work-sans;
    font-size: 2.326vw;
    font-style: normal;
    font-weight: 600;
    line-height: 3.721vw;
    color: $sw-white;
  }
  .scroll {
    .chats_listing {
      display: flex;
      gap: 3.605vw;
      margin-bottom: 4.186vw;
      width: max-content;
      .chat_item {
        width: 11.302vw;
        height: 11.302vw;
        border-radius: px-to-vw(4, 430);
        overflow: hidden;
        img {
          width: 100%;
          height: 100%;
          object-fit: contain;
        }
      }

      .chat_team {
        padding: px-to-vw(3, 430);
        border: none;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: rgba(255, 255, 255, 0.1);
        line-height: px-to-vw(14, 430);
        text-align: center;
        color: $white;
        font-family: $rubik;
        font-size: px-to-vw(12, 430);

        &.active {
          background-color: $primary-color;

          p {
            color: $black;
          }
        }
      }
    }
  }
}

@import "../../styles/base.scss";

.loader {
  position: relative;
  width: px-to-vw(80, 430);
  height: px-to-vw(80, 430);
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 20px auto;

  img {
    width: px-to-vw(50, 430);
    height: px-to-vw(50, 430);
    object-fit: contain;
  }
}

.loader:before,
.loader:after {
  content: "";
  border-radius: 50%;
  position: absolute;
  inset: 0;
}

.loader:after {
  box-shadow: 0 2.6px 0 $primary-color inset;
  animation: rotate 1.3s linear infinite;
}

@keyframes rotate {
  0% {
    transform: rotate(0);
  }

  100% {
    transform: rotate(360deg);
  }
}

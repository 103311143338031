@import "./reset.scss";
@import "./variables.scss";
@import "./mixins.scss";

* {
  box-sizing: border-box;
}

body {
  font-family: $rubik;
  overflow-x: hidden;
  background: linear-gradient(90deg, #005360 0.44%, #00182e 99.94%);
}

a {
  color: inherit;
  text-decoration: none;
  color: inherit;
}

strong {
  font-weight: bold;
}

em {
  font-style: italic;
}

.banner {
  height: auto;
  padding: px-to-vw(0, 430) px-to-vw(21, 430);
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: px-to-vw(8, 430);

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}

.main-container {
  width: 100vw;
  overflow-x: hidden;
  padding-bottom: px-to-vw(34, 430);
}

.container {
  padding: 0 px-to-vw(21, 430);
}

.btn {
  font-size: 2.791vw;
  font-weight: 700;
  line-height: 3.256vw;
  color: $sw-white;
  text-transform: uppercase;
  padding: 2.674vw;
  white-space: nowrap;

  border: 0.349vw solid rgba($color: $sw-white, $alpha: 0.5);
  background: rgba($color: $sw-white, $alpha: 0.3);

  &.selected {
    background-color: $primary-color;
    border-color: $primary-color;
    color: $sw-blue-medium;
  }

  &.fullWidth {
    display: block !important;
    width: 100% !important;
    text-align: center;
  }

  &.roundCorner {
    border-radius: 2px !important;
  }
}

* {
  -webkit-tap-highlight-color: transparent;
}

input {
  &::placeholder {
    color: rgba($white, 0.8);
  }
}

.no-results {
  margin-top: px-to-vw(40, 430);
  display: flex;
  align-items: center;
  justify-content: center;
  gap: px-to-vw(5, 430);
  padding: 0vw 4.88372vw;

  &.top {
    margin-bottom: px-to-vw(40, 430);
  }

  img {
    width: 100%;
    height: auto;
  }

  .text {
    text-align: center;
    h2 {
      color: $primary-color;
      font-size: px-to-vw(20, 430);
      margin-bottom: px-to-vw(-5, 430);
    }

    p {
      color: $white;
      line-height: px-to-vw(22, 430);
      font-size: px-to-vw(12, 430);
      margin-top: 10px;
    }
  }
}

.main-title {
  text-align: center;
  font-family: $rubik;
  font-size: px-to-vw(16, 430);
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: 0.32px;
  text-transform: uppercase;
  color: $white;
  margin: px-to-vw(16, 430) 0;
}

.custom-chat-button {
  position: fixed;
  bottom: 30px;
  right: 30px;
  background-color: #123456;
  color: white;
  padding: 10px;
  border: none;
  border-radius: 50%; /* Make the button round */
  cursor: pointer;
  font-size: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.custom-chat-button:hover {
  background-color: #0a294a;
}




@function px-to-vw($pxValue, $viewportWidth) {
  $vwValue: ($pxValue / $viewportWidth) * 100;
  @return #{$vwValue}vw;
}

@mixin absolute-center() {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

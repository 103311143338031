@import "../../styles/base.scss";

.outrights_section {
  margin-bottom: px-to-vw(32, 430);
  margin-top: px-to-vw(32, 430);
  h2 {
    font-family: $rubik;
    font-size: px-to-vw(20, 430);
    line-height: normal;
    color: $white;
    margin-bottom: px-to-vw(12, 430);
    letter-spacing: px-to-vw(0.4, 430);
    text-transform: uppercase;
    font-weight: 700;
  }
  .outrights {
    display: flex;
    flex-direction: column;
    gap: px-to-vw(16, 430);
    .outright {
      padding: px-to-vw(16, 430);
      background-color: rgba($white, 0.1);
      border-radius: px-to-vw(4, 430);
      h3 {
        color: $white;
        font-family: $rubik;
        font-size: px-to-vw(16, 430);
        font-style: normal;
        font-weight: 700;
        line-height: px-to-vw(24, 430);
        text-transform: uppercase;
        margin-bottom: px-to-vw(20, 430);
      }
      p {
        color: $white;
        font-family: $rubik;
        font-size: px-to-vw(12, 430);
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        margin-bottom: px-to-vw(16, 430);
        padding-right: px-to-vw(20, 430);
      }
      button {
        border: none;
        padding: px-to-vw(8, 430) px-to-vw(16, 430);
        text-align: center;
        font-family: $rubik;
        font-size: px-to-vw(12, 430);
        font-style: normal;
        font-weight: 700;
        line-height: normal;
        text-transform: uppercase;
        color: $sw-blue-medium;
        background-color: $sw-lime;
        border-radius: px-to-vw(2, 430);
        display: flex;
        align-items: center;
        justify-content: center;
        gap: px-to-vw(8, 430);
        width: 100%;

        img {
          width: px-to-vw(12, 430);
          height: px-to-vw(12, 430);
        }
      }

      .teamContainer {
        display: flex;
        align-items: center;
        justify-content: center;
        gap: px-to-vw(10, 430);
        border: 2px solid $primary-color;
        padding: 1.2vw;
        .teamLogo {
          width: 5.581vw;
          height: 5.581vw;
          img {
            height: 100%;
            object-fit: contain;
          }
        }
        .teamName {
          color: $primary-color;
          font-family: $rubik;
          font-size: 3.721vw;
          font-style: normal;
          font-weight: 500;
          line-height: normal;
          letter-spacing: 1px;
          padding: 0 !important;
          margin: 0 !important;
        }
        margin-bottom: 3.721vw;
      }

      .btn {
        max-width: 100%;
        width: 100%;
        position: relative;
        margin-top: 2.674vw;
        display: flex !important;
        justify-content: center !important;
        align-items: center;
        padding: 3.074vw 1.512vw;
        border-color: $primary-color;
        background-color: $primary-color;
        border-radius: 2px !important;
        .text {
          font-family: $rubik;
          font-size: px-to-vw(12, 430);
          font-style: normal;
          font-weight: 700;
          line-height: normal;
          text-transform: uppercase;
          color: $sw-blue-medium;
        }

        .icon {
          display: block;
          width: 4.907vw;

          -webkit-clip-path: polygon(0 0, 0 100%, 100% 100%, 100% 25%, 75% 0);
          clip-path: polygon(0 0, 0 100%, 50% 100%, 100% 0%, 100% 0);
          display: flex;
          align-items: center;
          img {
            width: 3.419vw;
            height: auto;
            display: block;
          }
        }
      }
    }
  }
  .points {
    display: block;
    color: $white;
    font-family: $rubik;
    font-size: 3.721vw;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: 1px;
    padding: 0 !important;
    margin: 0 !important;
    text-align: center;
  }
}

@import "../../styles/base.scss";

.best_player_card {
  margin-top: px-to-vw(24, 430);
  margin-bottom: px-to-vw(16, 430);

  .view_winner {
    background-color: $sw-red-dark;
    padding: px-to-vw(24, 430) 0;

    .card_title {
      font-size: px-to-vw(16, 430);
      font-weight: 700;
      line-height: normal;
      text-transform: uppercase;
      text-align: center;
      margin-bottom: px-to-vw(27, 430);
      color: $primary-color;
      font-family: $rubik;
    }

    .result {
      padding: 3.721vw;
      background: rgba($color: $sw-white, $alpha: 0.2);
      display: flex;
      flex-direction: row;
      align-items: center;
      gap: 3.721vw;

      .icon {
        display: block;
        width: 5.581vw;
        height: 5.581vw;

        img {
          object-fit: contain;
          width: 100%;
          height: 100%;
        }
      }

      .player {
        font-size: 3.721vw;
        font-weight: 600;
        line-height: 1;
        color: $sw-white;
      }

      .votes {
        flex-grow: 1;
        text-align: right;
        font-weight: 600;
        font-size: 2.558vw;
        line-height: 1;
        color: $sw-white;

        .value {
          font-size: 3.721vw;
          margin-right: 1.86vw;
        }
      }
    }

    .btn_vote {
      display: block;
      min-width: px-to-vw(80, 430);
      margin: px-to-vw(20, 430) auto 0 auto;
      padding: px-to-vw(8, 430) px-to-vw(16, 430);
      background-color: $primary-color;
      border: none;
      font-weight: 700;
      font-size: px-to-vw(12, 430);
      line-height: 1;
      text-align: center;
      text-transform: uppercase;
      color: $sw-blue-dark;
      border-radius: px-to-vw(4, 430);
    }

    .btn {
      padding: 0px 3.721vw;
      padding-right: 11.628vw;
      border: 1.5px solid $primary-color;
      background: rgba($white, 0.1);
      color: $white;
      position: relative;
      font-size: 2.791vw;
      line-height: 9.302vw;
      text-transform: none;
      border-radius: px-to-vw(4, 430);

      .icon {
        position: absolute;
        top: 0;
        right: 0;
        height: 9.302vw;
        width: 9.302vw;
        background-color: $primary-color;
        display: flex;
        align-items: center;
        justify-content: center;

        &.change_vote {
          width: max-content;
          padding: 0 1.86vw;
          color: $sw-blue-dark;
          text-transform: uppercase;
          font-size: 2.791vw;
          font-style: normal;
          font-weight: 700;
        }
      }
    }

    .most_voted_player {
      padding: 1.2vw 3.721vw;
      background: rgba($black, 0.2);
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-top: 1.86vw;
      border-radius: px-to-vw(4, 430);

      .player_details {
        display: flex;
        align-items: center;
        gap: 3.721vw;
        color: $white;

        .place {
          font-family: $rubik;
          font-size: 3.256vw;
          font-weight: 500;
          letter-spacing: px-to-vw(0.28, 430);
        }

        .player_name {
          font-size: 3.721vw;
          font-style: normal;
          font-weight: 500;
          line-height: normal;
          letter-spacing: 0.074vw;
        }

        img {
          width: 5.581vw;
          height: 5.581vw;
        }
      }

      .number_of_votes {
        font-size: 3.721vw;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        letter-spacing: 0.074vw;
        color: $white;

        span {
          font-size: 2.558vw;
          font-style: normal;
          font-weight: 400;
          line-height: normal;
          letter-spacing: 0.22px;
        }
      }

      .no_results {
        padding: 1.5vw 0;
        color: $sw-white;
      }
    }
  }

  .buttons {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 0.698vw;
    margin-bottom: 4.651vw;

    .btn {
      padding: px-to-vw(10, 430);
      font-family: $rubik;
      font-size: px-to-vw(9, 430);
      font-weight: 700;
      line-height: normal;
      text-transform: uppercase;
      color: $sw-white;
      background-color: $white;
      color: $black;
      border: none;

      &.active {
        background-color: $primary-color;
        color: $sw-blue-dark;
        border: none;
      }
    }
  }
}

.title {
  text-align: center;
  color: $primary-color;
  text-align: center;
  font-family: $rubik;
  font-size: px-to-vw(16, 430);
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: px-to-vw(0.32, 430);
  text-transform: uppercase;
  margin-bottom: px-to-vw(28, 430);
}

@import "../../styles/base.scss";

.prediction_header {
  &.with_background {
    color: red !important;
    padding: px-to-vw(16, 430);
    background-color: rgba(255, 255, 255, 0.1);
    border-radius: px-to-vw(4, 430);
    margin-bottom: px-to-vw(33, 430);

    .divider {
      padding: px-to-vw(8, 430) 0;

      hr {
        height: 0;
        border: 0;
        border-bottom: 1px solid rgba($white, 0.1);
        margin: 0;
      }
    }
  }
  .prediction_top {
    display: flex;
    align-items: center;
    justify-content: space-between;

    .group {
      display: flex;
      gap: px-to-vw(8, 430);
      flex-wrap: nowrap;
      .icon {
        width: px-to-vw(16, 430);
        height: px-to-vw(16, 430);
        img {
          width: 100%;
          height: 100%;
          object-fit: contain;
        }
      }
      p {
        font-family: $rubik;
        font-size: px-to-vw(10, 430);
        font-style: normal;
        font-weight: 300;
        line-height: px-to-vw(18, 430);
        color: $white;
      }
    }
  }

  .divider {
    padding: 2.721vw 0;

    hr {
      height: 0;
      border: 0;
      border-bottom: 1px solid rgba($white, 0.1);
      margin: 0;
    }
  }
  .teams {
    display: flex;
    justify-content: space-between;
    .live {
      padding: px-to-vw(2, 430) px-to-vw(4.5, 430) px-to-vw(2, 430) px-to-vw(16.5, 430);
      background-color: $sw-lime;
      height: max-content;
      position: relative;
      border-radius: px-to-vw(4, 430);
      p {
        color: $sw-blue-medium;
        font-family: $rubik;
        font-size: px-to-vw(12, 430);
        font-style: normal;
        font-weight: 700;
        line-height: normal;
        text-transform: uppercase;
      }
      &::after {
        content: "";
        position: absolute;
        top: px-to-vw(5, 430);
        left: px-to-vw(4.5, 430);
        width: px-to-vw(8, 430);
        height: px-to-vw(8, 430);
        background-color: #d00614;
        border-radius: 50%;
      }
    }
    .inner_container {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: space-between;
      gap: 2.428vw;

      .team_one,
      .team_two {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        gap: px-to-vw(7, 430);
        flex: 1;
        width: 100%;

        .name {
          font-family: $rubik;
          font-size: px-to-vw(16, 430);
          font-weight: 700;
          line-height: px-to-vw(24, 430);
          text-transform: uppercase;
          color: $white;
          max-width: max-content;
        }

        .flag {
          display: block;
          width: px-to-vw(24, 430);
          height: px-to-vw(24, 430);

          img {
            width: 100%;
            height: 100%;
            object-fit: contain;
          }
        }
      }
    }
  }
}

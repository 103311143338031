@import "../../styles/base.scss";

.odd_selector {
  h2 {
    font-family: $rubik;
    font-size: px-to-vw(16, 430);
    line-height: normal;
    color: $primary-color;
    margin-bottom: px-to-vw(16, 430);
  }

  .odd_options {
    display: flex;
    gap: px-to-vw(6, 430);

    .odd_option {
      flex: 1;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      text-align: center;
      gap: px-to-vw(6, 430);

      .logo {
        padding: px-to-vw(16, 430);
        border: none;
        width: 100%;
        height: px-to-vw(44, 430);
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: px-to-vw(4, 430);
        background-color: rgba(255, 255, 255, 0.1);
        img {
          width: auto;
          height: 100%;
          object-fit: contain;
        }

        p {
          color: $white;
          font-family: $rubik;
          font-size: px-to-vw(12, 430);
          line-height: normal;
        }

        &.active {
          background-color: $primary-color;

          p {
            color: $black;
          }
        }
      }

      .value {
        font-family: $rubik;
        font-size: px-to-vw(10, 430);
        line-height: px-to-vw(16, 430);
        color: $white;
      }
    }
  }
}

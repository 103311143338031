@import "../../styles/variables.scss";
@import "../../styles/mixins.scss";

.navigation {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: px-to-vw(8, 430);
  font-family: $rubik;
  border: none;
  margin-top: px-to-vw(5, 430);
  margin-bottom: px-to-vw(8, 430);

  .navigation_item {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: px-to-vw(16, 430);
    background-color: rgba(255, 255, 255, 0.1);
    padding: px-to-vw(15, 430) px-to-vw(5, 430);
    border-radius: px-to-vw(4, 430);

    border-bottom: 2px solid transparent;

    &.active {
      border-bottom: 2px solid $primary-color;
      .image_container {
        border: none;

        svg {
          path {
            fill: $primary-color;
          }
        }
      }

      p {
        text-shadow: 0 0 1px $white;
        color: $white;
      }
    }

    .image_container {
      width: px-to-vw(24, 430);
      height: px-to-vw(24, 430);
      display: flex;
      align-items: center;
      justify-content: center;
      svg {
        width: px-to-vw(24, 430);
        height: px-to-vw(24, 430);
      }

      div {
        div {
          display: flex;
          align-items: center;
          justify-content: center;
        }
      }
    }

    p {
      font-size: px-to-vw(12, 430);
      line-height: px-to-vw(14, 430);
      text-align: center;
      font-family: $rubik;
      font-size: px-to-vw(10, 430);
      font-style: normal;
      font-weight: 500;
      line-height: px-to-vw(16, 430); /* 160% */
      letter-spacing: px-to-vw(-0.2, 430);
      text-transform: uppercase;
      color: rgba($white, 0.8);
    }
  }
    & a:last-of-type .navigation_item {
      &.active {
        .image_container {
          svg {
            path {
              fill: none;
              stroke: $primary-color;
            }
          }
        }
      }
    }
}

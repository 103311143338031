@import "../../styles/base.scss";

.pop_up_prediction_container {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 100;
  background: transparent;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow-y: scroll;
  background-color: rgba($sw-blue-dark, 0.7);

  .pop_up_container__inner {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100% !important;
    height: auto;
    overflow: auto;
    background: linear-gradient(90deg, #005360 0.44%, #00182e 99.94%);

    padding: 5.581vw;

    .btn_close__container {
      display: flex;
      justify-content: flex-end;
      margin-bottom: 4.847vw;

      .btn_close {
        padding: 0;
        margin: 0;
        border: none;
        background: transparent;
        font-size: 0;

        img {
          width: 5.581vw;
          height: 5.581vw;
        }
      }
    }

    .send {
      background-color: $primary-color;
      color: $sw-blue-medium;
      font-family: $rubik;
      font-size: px-to-vw(15, 430);
      font-weight: 800;
      line-height: normal;
      width: 100%;
      margin: 0 auto;
      padding: px-to-vw(8, 430) px-to-vw(16, 430);
      border-radius: px-to-vw(4, 430);
      border: none;

      &:disabled {
        opacity: 0.4;
      }
    }

    .no_points {
      display: block;
      text-align: center;
      font-size: 2.791vw;
      font-weight: 400;
      line-height: 4.186vw;
      color: $sw-white;
      margin-top: 1.86vw;
      font-weight: 600;
    }

    .loader {
      @include absolute-center();
      top: 56%;
    }

    .odds_container {
      display: flex;
      flex-direction: column;
      gap: px-to-vw(30, 430);
      margin-bottom: px-to-vw(30, 430);
    }

    .error {
      color: red;
      margin: px-to-vw(12, 430) auto;
      font-size: px-to-vw(13, 430);
      text-align: center;
      font-family: $rubik;
    }

    .total_sum {
      font-family: $rubik;
      font-size: px-to-vw(12, 430);
      font-weight: 400;
      line-height: px-to-vw(16, 430);
      margin: px-to-vw(8, 430) auto;
      display: none;

      span {
        color: $primary-color;
        font-weight: 800;
        margin-left: px-to-vw(10, 430);
      }
    }

    .no_odds {
      margin: 20px auto;
      text-align: center;
      font-size: px-to-vw(14, 430);
      line-height: px-to-vw(18, 430);
      color: rgba($white, 0.5);
    }
  }
}

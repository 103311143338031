@import "../../styles/base.scss";

.leaderboards {
  margin-top: px-to-vw(24, 430);

  .filter_container {
    margin-bottom: 3.721vw;

    .filter {
      margin-top: px-to-vw(10, 430);
    }
  }

  .clasament_container {
    display: flex;
    flex-direction: column;
    gap: 1.86vw;

    .clasament_item {
      padding: 2.791vw 3.721vw;
      background: rgba($white, 0.1);
      display: flex;
      align-items: center;
      gap: 3.721vw;
      color: $white;
      font-family: $rubik;
      border-radius: px-to-vw(4, 430);

      &.user_profile,
      &.user_team {
        background-color: $sw-red !important;
        color: $white !important;
        margin-bottom: 50px;
      }

      &.custom_highlight,
      &.custom_highlight_team {
        background-color: $primary-color;
        color: $black;
      }

      .icon {
        width: auto;
        height: 5.581vw;

        &.first-place {
          width: 5.581vw;
          height: auto;
        }
      }

      &.first_places {
        .position {
          width: auto;
        }
      }

      .position {
        font-size: px-to-vw(16, 430);
        line-height: px-to-vw(22, 430);
        font-weight: 700;
        width: px-to-vw(25, 430);
        text-align: center;
      }

      .name {
        font-size: px-to-vw(16, 430);
        font-weight: 400;
        line-height: px-to-vw(18, 430);
      }

      .points {
        flex-grow: 1;
        text-align: right;
        font-size: px-to-vw(16, 430);
        font-weight: 700;
        line-height: px-to-vw(22, 430);

        span {
          font-size: px-to-vw(14, 430);
          font-weight: 400;
        }
      }
    }
  }

  ul.options-container {
    grid-template-columns: auto auto;
  }

  .no_results {
    color: rgba($color: $sw-white, $alpha: 0.5);
    text-align: center;
    border: none;
  }
  .buttons {
    display: flex;
    gap: px-to-vw(12, 430);
    margin-bottom: px-to-vw(16, 430);
    .button {
      flex: 1;
      padding: px-to-vw(13, 430) px-to-vw(2, 430);
      border: none;
      border-radius: px-to-vw(4, 430);
      background-color: rgba($white, 0.2);
      font-family: $rubik;
      font-size: px-to-vw(12, 430);
      font-style: normal;
      font-weight: 700;
      line-height: normal;
      text-transform: uppercase;
      color: $white;

      &.active {
        background-color: $primary-color;
        color: $sw-blue-dark;
        border-color: $primary-color;
      }
    }
  }
}

@import "../../styles/base.scss";

.pop-up-vote-container {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  height: 100%;
  width: 100%;
  z-index: 100;
  background: transparent;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow-y: scroll;
  background-color: rgba($sw-blue-dark, 0.7);
  padding: 0;

  .pop-up-container--inner {
    display: flex;
    flex-direction: column;
    min-height: 500px;
    width: 100%;
    max-height: 100%;
    height: 100%;
    overflow-y: scroll;
    background: $background;

    padding: 5.581vw;

    .btn-close--container {
      display: flex;
      justify-content: flex-end;
      .btn-close {
        padding: 0;
        margin: 0;
        border: none;
        background: transparent;
        font-size: 0;
        img {
          width: 5.581vw;
          height: 5.581vw;
        }
      }
    }

    .select-player {
      .title {
        margin: 3.721vw 0;
        font-size: 3.721vw;
        font-weight: 700;
        line-height: 1;
        text-transform: uppercase;
        color: $primary-color;
      }
      .options {
        display: flex;
        flex-direction: column;
        max-height: 78vh;
        overflow: auto;
        border-radius: px-to-vw(4, 430);
        background-color: rgba($sw-white, 0.1);
        padding: 0;

        .option {
          display: flex;
          align-items: center;
          justify-content: space-between;
          gap: 3.721vw;
          padding: 3.721vw px-to-vw(16, 430);
          transition: all 0.5s;

          &:not(:last-child) {
            border-bottom: 1px solid rgba($white, 0.1);
          }
          &.selected {
            .name,
            .value {
              color: $sw-lime;
            }
            .team-container {
              .name {
                color: $sw-lime;
              }
            }
          }
          .team-container {
            display: flex;
            align-items: center;
            gap: px-to-vw(10, 430);
            .team-logo {
              width: 5.581vw;
              height: 5.581vw;
              img {
                height: 100%;
                object-fit: contain;
              }
            }
            .name {
              font-family: $rubik;
              font-size: 3.721vw;
              font-style: normal;
              font-weight: 500;
              line-height: normal;
              letter-spacing: 0.32px;
              color: $sw-white;
            }
          }
          .value {
            color: $white;
            font-size: px-to-vw(14, 430);
          }
        }
      }
    }
    .btn {
      width: 100%;
      background-color: $primary-color;
      color: $sw-blue-dark;
      text-align: center;
      font-size: 2.791vw;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
      text-transform: uppercase;
      text-align: center;
      padding: 1.86vw 3.721vw;
      margin-top: 5.581vw;
      border-radius: 2px;
      border: none !important;
    }
  }
}

.loading-spinner {
  border: 4px solid rgba(0, 0, 0, 0.1);
  border-left-color: #09f;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  animation: spin 1s linear infinite;
  margin: 20px auto;
}

@keyframes spin {
  to {
    transform: rotate(360deg);
  }
}


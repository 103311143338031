@import "../../styles/base.scss";

.pop-up-vote-container {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  height: 100%;
  width: 100%;
  padding: px-to-vw(11, 430);
  z-index: 100;
  background: transparent;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow-y: scroll;
  background-color: rgba($sw-blue-dark, 0.7);

  .pop-up-container--inner {
    display: flex;
    flex-direction: column;
    min-height: 500px;
    width: 100%;
    max-height: 100%;
    overflow-y: scroll;
    background: $background;

    padding: 5.581vw;

    .btn-close--container {
      display: flex;
      justify-content: flex-end;
      .btn-close {
        padding: 0;
        margin: 0;
        border: none;
        background: transparent;
        font-size: 0;
        img {
          width: 5.581vw;
          height: 5.581vw;
        }
      }
    }

    .select-player {
      .title {
        margin: 3.721vw 0;
        font-size: 3.721vw;
        font-weight: 700;
        line-height: 1;
        text-transform: uppercase;
        color: $primary-color;
      }
      .players {
        display: flex;
        flex-direction: column;
        max-height: 50vh;
        overflow: auto;
        border-radius: px-to-vw(4, 430);
        background-color: rgba($sw-white, 0.1);
        padding: 0 px-to-vw(16, 430);

        .player {
          display: flex;
          align-items: center;
          gap: 3.721vw;
          padding: 3.721vw 0;
          transition: all 0.5s;

          &:not(:last-child) {
            border-bottom: 1px solid rgba($white, 0.1);
          }
          &.selected {
            background-color: rgba($sw-white, 0.3);
          }
          .team-logo {
            width: 5.581vw;
            height: 5.581vw;
            img {
              height: 100%;
              object-fit: contain;
            }
          }
          .player-name {
            font-family: $rubik;
            font-size: 3.721vw;
            font-style: normal;
            font-weight: 500;
            line-height: normal;
            letter-spacing: 0.32px;
            color: $sw-white;
          }
        }
      }
      .btn {
        width: 100%;
        border-color: $primary-color;
        background-color: $primary-color;
        color: $sw-blue-dark;
        text-align: center;
        font-size: 2.791vw;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
        text-transform: uppercase;
        text-align: center;
        padding: 1.86vw 3.721vw;
        margin-top: 5.581vw;
      }
    }
  }
}

@import "../../styles/base.scss";

.pop_up_terms_container {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  height: 100%;
  width: 100%;
  //padding: px-to-vw(13, 430);
  z-index: 100;
  background: transparent;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow-y: scroll;
  background-color: rgba($sw-blue-dark, 0.7);

  .pop_up_container__inner {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;
    height: 100%;
    min-height: 500px;
    max-height: 100%;
    background: $background;
    padding: 5.581vw;


    .btn_close__container {
      position: absolute;
      top: 40px;
      right: 23px;

      .btn_close {
        padding: 0;
        margin: 0;
        border: none;
        background: transparent;
        font-size: 0;

        img {
          width: 5.581vw;
          height: 5.581vw;
        }
      }
    }

    .data {
      padding-left: 4.581vw;
      max-height: 80%;
      overflow-y: scroll;

      .terms_title {
        font-size: 3.721vw;
        font-weight: 600;
        line-height: 4.419vw;
        text-transform: uppercase;
        color: $primary-color;
        margin-bottom: 2.791vw;
      }

      .terms_subtitle {
        font-size: 3.721vw;
        font-weight: 400;
        line-height: 4.419vw;
        text-transform: uppercase;
        color: $primary-color;
        margin-bottom: 3.721vw;
      }

      .data_container {
        * {
          font-family: $rubik;
          color: $white;
          font-size: 3.256vw;
          line-height: 3.721vw;

          strong {
            font-weight: 700;
          }
        }

        ul {
          & > li {
            margin-bottom: 2.791vw;
            list-style-type: disc;
          }
        }
        :global(.green) {
          color: $sw-lime;
          text-decoration: underline;
        }
      }
    }
  }

  .btn {
    background-color: $primary-color;
    color: $sw-blue-dark;
    width: max-content;
    margin: 20px auto 0 auto;
    border-radius: px-to-vw(4, 430);
  }
}

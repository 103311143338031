@import "../../styles/base.scss";

.pop-up-info-container {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  height: 100%;
  width: 100%;
  z-index: 100;
  background: transparent;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow-y: scroll;
  background-color: rgba($sw-blue-dark, 0.7);

  .pop-up-container--inner {
    display: flex;
    flex-direction: column;
    width: 100%;
    min-height: 500px;
    max-height: 100%;
    background: $background;
    animation: entry 0.5s;
    padding: 5.581vw;

    .btn-close--container {
      display: flex;
      justify-content: flex-end;
      margin-bottom: 6.047vw;
      .btn-close {
        padding: 0;
        margin: 0;
        border: none;
        background: transparent;
        font-size: 0;
        img {
          width: 5.581vw;
          height: 5.581vw;
        }
      }
    }

    .terms-title {
      font-size: 3.721vw;
      font-weight: 700;
      line-height: 4.419vw;
      color: $primary-color;
      margin-bottom: 3.721vw;
    }
    .teams_container {
      width: 100%;
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      gap: 2.093vw;
      max-height: 70%;
      overflow-y: auto;
      overflow-x: hidden;
      .team_container {
        width: 100%;
        height: 100%;
        border-radius: px-to-vw(4, 430);
        padding: 3.721vw px-to-vw(5, 430);
        background-color: rgba($sw-white, 0.1);
        display: flex;
        flex-direction: column;
        gap: 1.395vw;
        align-items: center;
        justify-content: center;
        .team_logo {
          width: 15.349vw;
          height: 15.349vw;
          img {
            width: 100%;
            height: 100%;
            object-fit: contain;
          }
        }
        .team_title {
          p {
            color: $sw-white;
            text-align: center;
            font-size: 3.721vw;
            font-style: normal;
            font-weight: 700;
            line-height: 5.581vw;
            word-wrap: break-word;
            word-break: normal;
          }
        }
      }
    }
  }
}

.register-page .terms-and-conditions .pop-up-terms-container .btn {
  width: min-content;
  display: inline-block;
  padding: 3.256vw 3.721vw;
  font-size: 2.558vw;
  line-height: 3.023vw;
  text-transform: uppercase;
  margin: 3.721vw auto 0;
}

@keyframes entry {
  from {
    scale: 0;
    opacity: 0.5;
  }
  to {
    scale: 1;
    opacity: 1;
  }
}

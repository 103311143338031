@import "../../styles/base.scss";

.select_container {
  &.colored {
    border: 2px solid $primary-color;
    border-radius: px-to-vw(4, 430);
    .selected_value {
      .icon {
        background-color: $primary-color;

        path {
          fill: $sw-blue-medium;
        }
      }
    }
  }
  &.odd_options {
    border: 2px solid $primary-color;
    border-radius: px-to-vw(4, 430);
    .selected_value {
      .icon {
        path {
          fill: $sw-lime;
        }
      }
      &.active {
        background-color: $sw-lime;
        color: $sw-blue-medium;
        .icon {
          path {
            fill: $sw-blue-medium;
          }
        }
      }
    }
    .options {
      .option {
        color: $white !important;
      }
    }
  }

  .selected_value {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    background-color: rgba(255, 255, 255, 0.1);
    color: $white;

    .text {
      padding: px-to-vw(12, 430) px-to-vw(40, 430) px-to-vw(12, 430) px-to-vw(16, 430);
      font-family: $rubik;
      font-size: px-to-vw(14, 430);
    }

    .icon {
      height: 100%;
      padding: px-to-vw(16, 430) px-to-vw(16, 430);
     

      .image {
        transition: all 0.3s;
        svg{
          width: px-to-vw(16, 430);
          height: px-to-vw(16, 430);
        }
        >div{
          display: flex;
          align-items: center;
          justify-content: center;
        }
      }

      &.active {
        .image {
          rotate: 180deg;
        }
      }
    }
  }

  .options {
    display: flex;
    flex-direction: column;
    background-color: transparent;
    transition: all 0.5s;
    transform-origin: top;
    max-height: 0;
    overflow: scroll;

    &.active {
      max-height: 200px;
    }

    .option {
      color: $white;
      padding: px-to-vw(12, 430) px-to-vw(16, 430);
      font-family: $rubik;
      font-size: px-to-vw(14, 430);
      line-height: px-to-vw(18, 430);

      &.active {
        background-color: rgba($white, 0.2);
      }
    }
  }
}

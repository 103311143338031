@import "../../styles/base.scss";

.odd_selector {
  color: $white;

  h2 {
    font-family: $rubik;
    font-size: px-to-vw(16, 430);
    line-height: normal;
    color: $primary-color;
    margin-bottom: px-to-vw(16, 430);
  }

  :global(.select_container) {

    li {
      color: $black !important;
    }
  }

  p.value {
    color: $white;
    font-family: $rubik;
    font-size: px-to-vw(10, 430);
    font-style: normal;
    font-weight: 400;
    line-height: px-to-vw(16, 430);
    width: max-content;
    margin: px-to-vw(9, 430) auto 0;
  }
}
